<template>
  <f7-popup class="filterselect-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_FILTER") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <div class="filter-container">
        <div class="title" v-if="isCountryFilter">
          <h1>{{ $t.getTranslation("LBL_COUNTRY_OF_ORIGIN") }}</h1>
        </div>

        <f7-list no-hairlines v-if="isCountryFilter">
          <f7-list-input
            v-model:value="formData.CountryOfOrigin"
            name="CountryOfOrigin"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="countryOfOriginList && countryOfOriginList.length > 0">
              <option value="ALL">{{ $t.getTranslation("LBL_ALL") }}</option>
              <option v-for="country in countryOfOriginList" :key="'cc_' + country.Code" :value="country.Code">{{ country.Name }}</option>
            </template>
          </f7-list-input>
        </f7-list>

        <!-- <f7-button class="apply-button" fill large @click="applyCountryOfOriginFilter">{{ $t.getTranslation("LBL_APPLY") }}</f7-button> -->

        <!-- <div class="title">
          <h1>{{ $t.getTranslation("LBL_WINERY") }}</h1>
        </div>

        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.Winery"
            name="Winery"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="wineryList && wineryList.length > 0">
              <option value="ALL">{{ $t.getTranslation("LBL_ALL") }}</option>
              <option v-for="winery in wineryList" :key="'ws_' + winery.Name" :value="winery.Code">{{ winery.TranslationName }}</option>
            </template>
          </f7-list-input>
        </f7-list> -->

        <div class="title">
          <h1>{{ $t.getTranslation("LBL_REGION") }}</h1>
        </div>

        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.Region"
            name="Region"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
            class="capitalize"
          >
            <template v-if="regionList && regionList.length > 0">
              <option value="ALL">{{ $t.getTranslation("LBL_ALL") }}</option>
              <option v-for="region in regionList" :key="'ws_' + region.Name" :value="region.Name">{{ region.TranslationName }}</option>
            </template>
          </f7-list-input>
        </f7-list>

        <div class="title">
          <h1>{{ $t.getTranslation("LBL_WINE_SWEETNESS") }}</h1>
        </div>

        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.Sweetness"
            name="Sweetness"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="wineSweetnessList && wineSweetnessList.length > 0">
              <option value="ALL">{{ $t.getTranslation("LBL_ALL") }}</option>
              <option v-for="wineSweetness in wineSweetnessList" :key="'ws_' + wineSweetness.Name" :value="wineSweetness.TranslationName">{{ wineSweetness.TranslationName }}</option>
            </template>
          </f7-list-input>
        </f7-list>

        <!-- <f7-button class="apply-button" fill large @click="applyWineSweetnessFilter">{{ $t.getTranslation("LBL_APPLY") }}</f7-button> -->

        <div class="title">
          <h1>{{ $t.getTranslation("LBL_WINE_ACIDITY") }}</h1>
        </div>

        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.Acidity"
            name="Acidity"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="wineAcidityList && wineAcidityList.length > 0">
              <option value="ALL">{{ $t.getTranslation("LBL_ALL") }}</option>
              <option v-for="wineAcidity in wineAcidityList" :key="'wa_' + wineAcidity.Name" :value="wineAcidity.TranslationName">{{ wineAcidity.TranslationName }}</option>
            </template>
          </f7-list-input>
        </f7-list>

        <div class="title">
          <h1>{{ $t.getTranslation("LBL_BOTTLES") }}</h1>
        </div>

        <!-- <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.PackingQty"
            name="PackingQty"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option value="ALL">{{ $t.getTranslation('LBL_ALL') }}</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="3">4</option>
            <option value="6">6</option>
            <option value="12">12</option>
          </f7-list-input>
        </f7-list> -->

        <!-- <f7-button class="apply-button" fill large @click="applyWineAcidityFilter">{{ $t.getTranslation("LBL_APPLY") }}</f7-button> -->

        <div v-if="!$configs?.hideProductPrice">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_PRICE_RANGE") }}</h1>
          </div>

          <f7-row class="stepper-container">
            <f7-col>
              <h4>{{ $t.getTranslation("LBL_MINIMUM") }}</h4>
              <f7-stepper v-model:value="formData.minPrice" :min="0" :max="999999999" :step="500" raised round :manual-input-mode="true"></f7-stepper>
            </f7-col>
            <f7-col>
              <h4>{{ $t.getTranslation("LBL_MAXIMUM") }}</h4>
              <f7-stepper v-model:value="formData.maxPrice" :min="0" :max="999999999" :step="500" raised round :manual-input-mode="true"></f7-stepper>
            </f7-col>
          </f7-row>
        </div>

        <!-- <f7-button class="apply-button" fill large @click="applyPriceFilter">{{ $t.getTranslation("LBL_APPLY") }}</f7-button> -->

        <div class="title">
          <h1>{{ $t.getTranslation("LBL_RATINGS") }}</h1>
        </div>

        <f7-row class="stepper-container">
          <f7-col>
            <h4>{{ $t.getTranslation("LBL_MINIMUM") }}</h4>
            <f7-stepper v-model:value="formData.minRating" :min="0" :max="5" :step="1" raised round></f7-stepper>
          </f7-col>
          <f7-col>
            <h4>{{ $t.getTranslation("LBL_MAXIMUM") }}</h4>
            <f7-stepper v-model:value="formData.maxRating" :min="0" :max="5" :step="1" raised round></f7-stepper>
          </f7-col>
        </f7-row>

        <!-- <f7-button class="apply-button" fill large @click="applyRatingFilter">{{ $t.getTranslation("LBL_APPLY") }}</f7-button> -->
        <f7-button class="apply-button" fill large @click="applyFilter">{{ $t.getTranslation("LBL_APPLY") }}</f7-button>

        <f7-button class="reset-button" large @click="resetFilter">{{ $t.getTranslation("LBL_RESET") }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { reactive, computed, inject } from "vue";
import { f7 } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";
import { configs, extendedConfigs } from "@/utils/configs.js";

import { useStore } from "@/store";

export default {
  name: "ProductFilterPopUpComponent",
  components: {},
  props: {
    open: Boolean,
    isCountryFilter: { type: Boolean, default: true },
  },
  emits: ["opened", "closed", "selected", "cleared"],
  setup(props, { emit }) {
    const $t = inject("$translation");
    const store = useStore();

    store.dispatch("config/fetchData", { params: { countryOfOriginList: 1, wineSweetnessList: 1, wineAcidityList: 1, regionList: 1, wineryList: 1 } });

    const countryOfOriginList = computed(() => store.getters["config/getData"]?.countryOfOriginList);
    const wineSweetnessList = computed(() => store.getters["config/getData"]?.wineSweetnessList);
    const wineAcidityList = computed(() => store.getters["config/getData"]?.wineAcidityList);
    const regionList = computed(() => store.getters["config/getData"]?.regionList);
    const wineryList = computed(() => store.getters["config/getData"]?.wineryList);

    //const productMoqList = computed(() => store.getters["config/getData"]?.productMoqList);
    //const productPackingQtyList = computed(() => store.getters['config/getData']?.productPackingQtyList)
    const initialState = {
      Region: "ALL",
      Winery: "ALL",
      CountryOfOrigin: "ALL",
      Sweetness: "ALL",
      Acidity: "ALL",
      PackingQty: "ALL",
      minPrice: 0,
      maxPrice: 0,
      minRating: 0,
      maxRating: 0,
      Moq: "ALL",
    };

    const formData = reactive({ ...initialState });

    const openPopup = () => {
      emit("opened", true);
    };

    const closePopup = () => {
      emit("closed", true);
    };

    const applyFilter = () => {
      // CHECKER FOR FILTER VALUES
      if (!helpers.isBlank(formData.minPrice) && !helpers.isBlank(formData.maxPrice)) {
        if (formData.minPrice > formData.maxPrice) {
          helpers.createNotification({
            type: "error",
            title: $t.getTranslation("LBL_ERROR"),
            message: $t.getTranslation("ERR_MIN_PRICE_MUST_BE_LESS_THAN_MAX_PRICE"),
          });
          return;
        }
      }

      if (!helpers.isBlank(formData.minRating) && !helpers.isBlank(formData.maxRating)) {
        if (formData.minRating > formData.maxRating) {
          helpers.createNotification({
            type: "error",
            title: $t.getTranslation("LBL_ERROR"),
            message: $t.getTranslation("ERR_MIN_RATING_MUST_BE_LESS_THAN_MAX_RATING"),
          });
          return;
        }
      }

      f7.popup.close();
      let filterValue = [];

      if (formData["CountryOfOrigin"] != "" && formData["CountryOfOrigin"] != "ALL") {
        filterValue.push({ FilterType: extendedConfigs?.isElastic ? "CountryCode" : "CTR.CountryCode", FilterValue: formData.CountryOfOrigin });
      }

      if (formData["Sweetness"] != "" && formData["Sweetness"] != "ALL") {
        filterValue.push({ FilterType: "Sweetness", FilterValue: formData.Sweetness });
      }

      if (formData["Acidity"] != "" && formData["Acidity"] != "ALL") {
        filterValue.push({ FilterType: "Acidity", FilterValue: formData.Acidity });
      }

      if (formData["Winery"] != "" && formData["Winery"] != "ALL") {
        let type = "Winery";
        if (extendedConfigs?.isElastic) {
          type = "WineryCode";
        }
        filterValue.push({ FilterType: type, FilterValue: formData.Winery });
      }

      if (formData["Region"] != "" && formData["Region"] != "ALL") {
        filterValue.push({ FilterType: "Region", FilterValue: formData.Region });
      }

      // if (formData['minPrice'] >= 0 && formData['maxPrice'] > 0) {
      //   filterValue.push({ FilterType: 'ProductPrice', FilterValue: `${formData.minPrice},${formData.maxPrice}` })
      // }

      if (formData["minPrice"] != "" && formData["minPrice"] != "ALL") {
        filterValue.push({ FilterType: "PriceMin", FilterValue: formData.minPrice });
      }

      if (formData["maxPrice"] != "" && formData["maxPrice"] != "ALL") {
        filterValue.push({ FilterType: "PriceMax", FilterValue: formData.maxPrice });
      }

      // if (formData['minRating'] >= 0 && formData['maxRating'] > 0) {
      //   filterValue.push({ FilterType: 'TotalRating', FilterValue: `${formData.minRating},${formData.maxRating}` })
      // }

      if (formData["minRating"] != "" && formData["minRating"] != "ALL") {
        filterValue.push({ FilterType: "RatingMin", FilterValue: formData.minRating });
      }

      if (formData["maxRating"] != "" && formData["maxRating"] != "ALL") {
        filterValue.push({ FilterType: "RatingMax", FilterValue: formData.maxRating });
      }

      // if (formData["Moq"] != "" && formData["Moq"] != "ALL") {
      //   filterValue.push({ FilterType: "P.Moq", FilterValue: formData.Moq });
      // }

      if (formData["PackingQty"] != "" && formData["PackingQty"] != "ALL") {
        filterValue.push({ FilterType: extendedConfigs?.isElastic ? "PackingQty" : "P.PackingQty", FilterValue: formData.PackingQty });
      }

      emit("selected", {
        FilterType: "MULTIPLE",
        FilterValue: extendedConfigs?.isElastic ? filterValue : JSON.stringify(filterValue),
        Winery: formData.Winery || null,
      });
    };

    const resetFilter = (data) => {
      Object.assign(formData, initialState);

      emit("cleared");
    };

    // const applyCountryOfOriginFilter = () => {
    //   f7.popup.close();

    //   emit("selected", {
    //     FilterType: "PA.CountryOfOrigin",
    //     FilterValue: formData.CountryOfOrigin,
    //   });

    //   Object.assign(formData, {
    //     ...initialState,
    //     CountryOfOrigin: formData.CountryOfOrigin,
    //   });
    // };

    // const applyWineSweetnessFilter = () => {
    //   f7.popup.close();

    //   emit("selected", {
    //     FilterType: "Sweetness",
    //     FilterValue: formData.Sweetness,
    //   });

    //   Object.assign(formData, {
    //     ...initialState,
    //     Sweetness: formData.Sweetness,
    //   });
    // };

    // const applyWineAcidityFilter = () => {
    //   f7.popup.close();

    //   emit("selected", {
    //     FilterType: "Acidity",
    //     FilterValue: formData.Acidity,
    //   });

    //   Object.assign(formData, {
    //     ...initialState,
    //     Acidity: formData.Acidity,
    //   });
    // };

    // const applyPriceFilter = () => {
    //   f7.popup.close();

    //   emit("selected", {
    //     FilterType: "PPL.RegularSellingPrice",
    //     FilterValue: [formData.minPrice, formData.maxPrice],
    //   });

    //   Object.assign(formData, {
    //     ...initialState,
    //     minPrice: formData.minPrice,
    //     maxPrice: formData.maxPrice,
    //   });
    // };

    // const applyRatingFilter = () => {
    //   f7.popup.close();

    //   emit("selected", {
    //     FilterType: "Rating",
    //     FilterValue: [formData.minRating, formData.maxRating],
    //   });

    //   Object.assign(formData, {
    //     ...initialState,
    //     minRating: formData.minRating,
    //     maxRating: formData.maxRating,
    //   });
    // };

    return {
      regionList,
      wineryList,
      countryOfOriginList,
      wineSweetnessList,
      wineAcidityList,
      formData,
      openPopup,
      closePopup,
      applyFilter,
      resetFilter,
      store,
      //productPackingQtyList
    };
  },
};
</script>

<style scoped>
.filterselect-popup.popup {
  --f7-popup-tablet-height: 630px;
}
.filterselect-popup.popup .navbar .title {
  margin-left: 15px;
}
.filterselect-popup .filter-container {
  width: calc(100% - 60px);
  margin: 20px auto;
}
.filterselect-popup .apply-button {
  margin: 30px auto 0px;
}
.filterselect-popup.popup .stepper-container h4 {
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
}
.filter-container .title {
  margin-bottom: 10px;
}
.filter-container .list {
  width: 100%;
  --f7-list-item-padding-vertical: 0px;
  --f7-list-item-padding-horizontal: 0px;
}
</style>
